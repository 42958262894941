<template>
  <div id="inspector-footer"
       :class="{
           'has-content': hasContent,
           'is-anchored': $layoutAnchored || isMobile,
           'is-dark': $themeDark,
           'is-floating': $layoutFloating && !isMobile,
           'is-fullscreen': isFullScreen,
           'is-mobile': isMobile,
           'primary-menu-is-active': primaryMenu.active,
       }"
       ref="inspectorFooter"
  ></div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

export default {
  name: 'InspectorFooter',
  computed: {
    hasContent() {
      return this.inspector && this.inspector.hasFooterContent;
    },
    inspector() {
      return this.$store.state.ui.inspector;
    },
    isFullScreen() {
      return this.$route.meta.fullScreen || this.inspector.fullScreen;
    },
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    primaryMenu() {
      return this.$store.state.ui.primaryMenu;
    },
    childNodes() {
      return this.$refs.inspectorFooter ? this.$refs.inspectorFooter.hasChildNodes() : null;
    }
  },
  mounted() {
    this.$emit('mounted'); // We do this because the InspectorFooter needs to be rendered before content can be teleported to it.
    const el = document.getElementById('inspector-footer');
    el.addEventListener('DOMNodeInserted', this.domNodesInserted, false);
    el.addEventListener('DOMNodeRemoved', this.domNodesRemoved, false);
  },
  destroyed() {
    const el = document.getElementById('inspector-footer');
    if (el) {
      el.removeEventListener('DOMNodeInserted', this.domNodesInserted, false);
      el.removeEventListener('DOMNodeRemoved', this.domNodesRemoved, false);
    }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      inspectorUpdate: 'ui/inspector',
    }),
    domNodesRemoved() {
      this.inspectorUpdate({
        hasFooterContent: false,
      });
    },
    domNodesInserted() {
      this.inspectorUpdate({
        hasFooterContent: true,
      });
    },
  },
  props: {},
}
</script>

<style scoped lang="scss">
#inspector-footer {
  background: $theme-light-background-color;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  z-index: $zindex-inspector + 2;

  &.has-content {
    border-top: 1px solid $navigation-border-color;
    padding: 15px;
  }

  &.is-dark {
    background: $theme-dark-background-color
  }

  &.is-floating {
    border-radius: 0 0 $layout-floating-border-radius $layout-floating-border-radius;
  }
}
</style>
