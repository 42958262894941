var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"inspectorFooter",class:{
         'has-content': _vm.hasContent,
         'is-anchored': _vm.$layoutAnchored || _vm.isMobile,
         'is-dark': _vm.$themeDark,
         'is-floating': _vm.$layoutFloating && !_vm.isMobile,
         'is-fullscreen': _vm.isFullScreen,
         'is-mobile': _vm.isMobile,
         'primary-menu-is-active': _vm.primaryMenu.active,
     },attrs:{"id":"inspector-footer"}})
}
var staticRenderFns = []

export { render, staticRenderFns }